import { ActionContext, Module } from "vuex";

import paymentApi from "./tabs.api";
import actionHelper from "utils/actionHelper";
import {
  closeAllTabsSessionsActionPayload,
  closeTabSessionsActionPayload,
  createTabActionPayload,
  createTabActionReturn,
  createTabSessionsPayload,
  deleteTabSessionsPayload,
  emptyTabFactory,
  getTabSessionsPayload,
  goToTabActivePathActionPayload,
  ITab,
  ITabsState,
  onAnyTabDataUpdateActionPayload,
  onStartFlyBookActionPayload,
  onStartHotelBookActionPayload,
  onStartSearchActionPayload,
  onStartStatPackageBookActionPayload,
  openNewTabActionPayload,
  reloadTabsOnLoginActionPayload,
  selectActiveTabActionPayload,
  setTabExpiredActionPayload,
  setTabUnExpiredActionPayload,
  updateTabSessionsActionPayload,
  updateTabSessionsPayload
} from "./types/tabs.types";
import { TabSessionResource } from "tl-api-doc/typescript-generator";
import omit from "lodash/omit";
import pick from "lodash/pick";
import router from "applications/desktop/router";
import { RootState } from "applications/desktop/store";
import _ from "lodash";

const SET_TABS_FROM_API = actionHelper("SET_TABS_FROM_API");
const CREATE_TAB = actionHelper("CREATE_TAB");

const UPDATE_TAB = actionHelper("UPDATE_TAB");
const SELECT_ACTIVE_TAB = actionHelper("SELECT_ACTIVE_TAB");

const RELOAD_TABS_ON_LOGIN = actionHelper("RELOAD_TABS_ON_LOGIN");
const SET_TAB_EXPIRED = actionHelper("SET_TAB_EXPIRED");

const ON_CLOSE_TAB = actionHelper("ON_CLOSE_TAB");
const ON_CLOSE_ALL_TABS = actionHelper("ON_CLOSE_ALL_TABS");
export default ({
  api
}: {
    api: ReturnType<typeof paymentApi>;
}): Module<ITabsState, RootState> => ({
  namespaced: true,

  state: {
    tabs: [],
    rawTabs: [],
    activeTabIndex: 0,
    selectedTabId: null
  },

  getters: {
    tabs: state => state.tabs,
    activeTabIndex: state => state.activeTabIndex,
    selectedTabId: state => state.selectedTabId,
    selectedTab: state => state.tabs?.[state.activeTabIndex] ?? null
  },

  actions: {
    async reloadTabsOnLogin({
      dispatch,
      commit,
      state,
      rootGetters,
      rootState
    }: ActionContext<ITabsState, RootState>, payload: reloadTabsOnLoginActionPayload) {
      commit(RELOAD_TABS_ON_LOGIN.INITIAL);

      const deviceFingerprint: string = rootGetters["ROOT_MODULE/deviceFingerprint"];
      const websiteKey: string = rootGetters["ROOT_MODULE/websiteKey"];
      const getTabSessionsPayload: getTabSessionsPayload = {
        uuid: payload.uuid,
        deviceFingerprints: deviceFingerprint ? [deviceFingerprint] : null,
        websiteKeys: [websiteKey]
      };
      const tabsSessionResource: TabSessionResource[] = await dispatch("getTabSessions", getTabSessionsPayload);

      console.log("tabsSessionResource", tabsSessionResource);
      // если есть табы на беке

      const isB2b2c = rootGetters["ROOT_MODULE/isB2b2c"];
      console.log("isB2b2c", isB2b2c, rootGetters, rootState);
      if (!!tabsSessionResource && Array.isArray(tabsSessionResource) && tabsSessionResource.length > 0 &&
                !isB2b2c) {
        console.log("если есть табы tabsSessionResource", tabsSessionResource);
        commit(RELOAD_TABS_ON_LOGIN.SUCCEEDED, tabsSessionResource);
      } else {
        // если нет табов на беке
        console.log("если нет табов tabsSessionResource", tabsSessionResource);

        const createTabActionPayload: createTabActionPayload = {
          uuid: payload.uuid
        };
        await dispatch("createTab", createTabActionPayload);
      }

      // после того как сохранили их выбираем последний
      const selectActiveTabPayload: selectActiveTabActionPayload = {
        tabSessionId: state.tabs.at(-1).tabSessionId,
        uuid: payload.uuid
      };

      if (!payload.isNeedBlockSelectTab) {
        await dispatch("selectActiveTab", selectActiveTabPayload);
      }
      return state.tabs;
    },

    async createTab({
      commit,
      rootState,
      rootGetters
    }: ActionContext<ITabsState, RootState>, payload: createTabActionPayload) {
      try {
        const deviceFingerprint = rootGetters["ROOT_MODULE/deviceFingerprint"];
        const websiteKey = rootGetters["ROOT_MODULE/websiteKey"];
        console.log("createTab", rootState, rootGetters, deviceFingerprint, websiteKey);
        const payloadToAllSiteTypes: createTabSessionsPayload = {
          ...payload,
          deviceFingerprint: deviceFingerprint ?? null,
          websiteKey
        };
        const { data } = await api.createTabSessions(payloadToAllSiteTypes);
        commit(CREATE_TAB.SUCCEEDED, data);
        return data;
      } catch (e) {
        commit(CREATE_TAB.FAILED, e);
      }
    },
    async updateTab({ commit }: ActionContext<ITabsState, RootState>, payload: updateTabSessionsActionPayload) {
      commit(UPDATE_TAB.INITIAL);

      try {
        const preparedPayload: updateTabSessionsPayload = {
          rawData: omit(payload, ["tabSessionId", "tabSessionResource"]),
          uuid: payload.uuid,
          orderIds: [],
          customerIds: []

        };
        if (payload.orderId) {
          preparedPayload.orderIds = [payload.orderId];
        }

        if (!!payload.customerIds && Array.isArray(payload.customerIds) && payload.customerIds.length > 0) {
          preparedPayload.customerIds = payload.customerIds;
        }

        const { data } = await api.updateTabSessions(payload.tabSessionId, preparedPayload);
        commit(UPDATE_TAB.SUCCEEDED, data);
        return data;
      } catch (e) {
        commit(UPDATE_TAB.FAILED, e);
      }
    },
    async getTabSessions({ commit }: ActionContext<ITabsState, RootState>, payload: getTabSessionsPayload) {
      const { data } = await api.getTabSessions(payload);
      commit(SET_TABS_FROM_API.SUCCEEDED, data.content);

      return data.content;
    },
    async selectActiveTab({
      commit,
      dispatch
    }: ActionContext<ITabsState, RootState>, payload: selectActiveTabActionPayload) {
      commit(SELECT_ACTIVE_TAB.SUCCEEDED, payload.tabSessionId);

      await dispatch("goToTabActivePath", payload);

      if (payload.fromTab && payload.fromTab.activePath === "/") {
        await dispatch("closeTab", {
          uuid: payload.uuid,
          tabSessionId: payload.fromTab.tabSessionId
        });
      }
    },
    async goToTabActivePath({ state }, payload: goToTabActivePathActionPayload) {
      const selectedTab = state.tabs.find(tab => tab.tabSessionId === payload.tabSessionId);

      const activePath = selectedTab.activePath;
      // check if activePath is not null and different from current path

      const currentPath = router.currentRoute.path;

      if (activePath && activePath !== currentPath) {
        await router.push({
          path: selectedTab.activePath
        });
      }
    },
    async closeTab({
      commit,
      state,
      dispatch
    }: ActionContext<ITabsState, RootState>, payload: closeTabSessionsActionPayload) {
      const deletePayload: deleteTabSessionsPayload = {
        uuid: payload.uuid,
        tabSessionId: payload.tabSessionId
      };
      try {
        const isLastTab = state.tabs.length === 1;
        const isCloseActiveTab = state.selectedTabId === payload.tabSessionId;
        // если есть несколько табов и мы пытаемся закрыть нужно выбрать предыдущий
        const previousTab = state.tabs[state.tabs.findIndex(tab => tab.tabSessionId === payload.tabSessionId) -
                1];

        try {
          await api.deleteTabSessions(deletePayload.tabSessionId, deletePayload);
        } catch (e) {
          console.log("а таб то уже или закрыт или вообще странно что", e);
        }
        commit(ON_CLOSE_TAB.SUCCEEDED, payload.tabSessionId);

        if (isLastTab) {
          const createTabActionPayload: createTabActionPayload = {
            uuid: payload.uuid
          };
          await dispatch("createTab", createTabActionPayload);

          // после того удалили выбираем последний их имеющихся
          const selectActiveTabPayload: selectActiveTabActionPayload = {
            tabSessionId: state.tabs.at(-1).tabSessionId,
            uuid: payload.uuid
          };

          await dispatch("selectActiveTab", selectActiveTabPayload);
        }

        if (isCloseActiveTab && previousTab) {
          // select previous tab

          const selectActiveTabPayload: selectActiveTabActionPayload = {
            tabSessionId: previousTab.tabSessionId,
            uuid: payload.uuid
          };

          await dispatch("selectActiveTab", selectActiveTabPayload);
        }
      } catch (e) {
        commit(ON_CLOSE_TAB.FAILED, e);
      }
    },

    // close active tab
    async closeActiveTab({
      state,
      dispatch
    }: ActionContext<ITabsState, RootState>, { uuid }: { uuid: string }): Promise<void> {
      await dispatch("closeTab", {
        uuid,
        tabSessionId: state.selectedTabId
      });
    },

    async closeAllTabs({
      commit,
      dispatch,
      state
    }: ActionContext<ITabsState, RootState>, payload: closeAllTabsSessionsActionPayload) {
      try {
        const promises = state.tabs.map(tab => api.deleteTabSessions(tab.tabSessionId, {
          uuid: payload.uuid,
          tabSessionId: tab.tabSessionId
        }));
        await Promise.all(promises);
        commit(ON_CLOSE_ALL_TABS.SUCCEEDED);

        await new Promise(resolve => setTimeout(resolve, 1000));

        // // после удаления все табов, создаем новый
        const createTabActionPayload: createTabActionPayload = {
          uuid: payload.uuid
        };
        await dispatch("createTab", createTabActionPayload);

        // далее выбираем его
        const selectActiveTabPayload: selectActiveTabActionPayload = {
          tabSessionId: state.tabs.at(-1).tabSessionId,
          uuid: payload.uuid
        };
        await dispatch("selectActiveTab", selectActiveTabPayload);
      } catch (e) {
        commit(ON_CLOSE_ALL_TABS.FAILED, e);
      }
    },
    async openNewTab({ dispatch }: ActionContext<ITabsState, RootState>, payload: openNewTabActionPayload) {
      const createTabPayload: createTabActionPayload = {
        uuid: payload.uuid
      };

      const tabResource: TabSessionResource = (await dispatch(
        "createTab",
        createTabPayload
      ) as Awaited<createTabActionReturn>);

      const selectActiveTabPayload: selectActiveTabActionPayload = {
        tabSessionId: tabResource.entityId,
        uuid: payload.uuid
      };

      await dispatch("selectActiveTab", selectActiveTabPayload);

      if (payload.fromTab && payload.fromTab.activePath === "/") {
        await dispatch("closeTab", {
          uuid: payload.uuid,
          tabSessionId: payload.fromTab.tabSessionId
        });
      }
    },

    async onAnyTabDataUpdate({
      dispatch,
      state
    }: ActionContext<ITabsState, RootState>, payload: onAnyTabDataUpdateActionPayload) {
      const sourceTab = payload.selectedTab
        ? payload.selectedTab
        : state.tabs.find(tab => tab.tabSessionId === payload.tabSessionId);

      if (!sourceTab) {
        console.error("sourceTab not found");
      }
      const updateTabPayload: updateTabSessionsActionPayload = {
        ..._.cloneDeep(sourceTab),
        ..._.cloneDeep(_.omit(
          payload,
          "selectedTab"
        ))
      };
      await dispatch("updateTab", updateTabPayload);
    },
    async onStartFlyBook({
      dispatch,
      state
    }: ActionContext<ITabsState, RootState>, payload: onStartFlyBookActionPayload) {
      const sourceTab = state.tabs.find(tab => tab.tabSessionId === payload.tabSessionId);

      if (!sourceTab) {
        console.error("sourceTab not found");
      }
      const activePath = `/order/${payload.orderId}`;
      const updateTabPayload: updateTabSessionsActionPayload = {
        ..._.cloneDeep(sourceTab),
        uuid: payload.uuid,
        tabSessionId: state.selectedTabId,
        activePath,
        productId: payload.productId,
        orderId: payload.orderId,
        orderItemIds: payload.orderItemId,
        orderItems: payload.orderItems
      };

      const createTabPayload: createTabActionPayload = {
        uuid: payload.uuid,
        rawData: {
          ..._.cloneDeep(updateTabPayload),
          tabSessionId: undefined
        }
      };

      const tabResource: TabSessionResource = (await dispatch(
        "createTab",
        createTabPayload
      ) as Awaited<createTabActionReturn>);

      const selectActiveTabPayload: selectActiveTabActionPayload = {
        tabSessionId: tabResource.entityId,
        uuid: payload.uuid
      };

      await dispatch("selectActiveTab", selectActiveTabPayload);

      const addFlightProductToTabSessionsPayloadArray = payload.productId.map((productId: string) => {
        const addFlightProductToTabSessionsPayload = {
          uuid: payload.uuid,
          tabSessionId: tabResource.entityId,
          productId
        };
        return addFlightProductToTabSessionsPayload;
      });

      await Promise.all(addFlightProductToTabSessionsPayloadArray.map((addFlightProductToTabSessionsPayload) => api.addFlightProductToTabSessions(
        tabResource.entityId,
        addFlightProductToTabSessionsPayload
      )));
    },

    // write same logic for hotel

    async onStartHotelBook({
      dispatch,
      state
    }: ActionContext<ITabsState, RootState>, payload: onStartHotelBookActionPayload) {
      const sourceTab = state.tabs.find(tab => tab.tabSessionId === payload.tabSessionId);

      if (!sourceTab) {
        console.error("sourceTab not found");
      }
      const activePath = `/hotel-order/${payload.orderId}`;
      const updateTabPayload: updateTabSessionsActionPayload = {
        ..._.cloneDeep(sourceTab),
        uuid: payload.uuid,
        tabSessionId: state.selectedTabId,
        activePath,
        productId: payload.productId,
        tabIndex: 0,
        // rawProduct: payload.productRaw,
        // rawProductPrice: payload.productPrice,
        orderId: payload.orderId,
        agency: payload.agency,
        agencyId: payload.agencyId
        // orderItemIds: payload.orderItemId,
        // orderItems: payload.orderItems
      };

      const createTabPayload: createTabActionPayload = {
        uuid: payload.uuid,
        rawData: {
          ..._.cloneDeep(updateTabPayload),
          tabSessionId: undefined
        }
      };

      const tabResource: TabSessionResource = (await dispatch(
        "createTab",
        createTabPayload
      ) as Awaited<createTabActionReturn>);

      const selectActiveTabPayload: selectActiveTabActionPayload = {
        tabSessionId: tabResource.entityId,
        uuid: payload.uuid
      };

      await dispatch("selectActiveTab", selectActiveTabPayload);

      const addHotelProductToTabSessionsPayloadArray = payload.productId.map((productId: string) => {
        const addHotelProductToTabSessionsPayload = {
          uuid: payload.uuid,
          tabSessionId: tabResource.entityId,
          productId
        };

        return addHotelProductToTabSessionsPayload;
      });

      await Promise.all(addHotelProductToTabSessionsPayloadArray.map((addHotelProductToTabSessionsPayload) => api.addHotelProductToTabSessions(
        tabResource.entityId,
        addHotelProductToTabSessionsPayload
      )));
    },

    // write same logic for package

    async onStartStatPackageBook({
      dispatch,
      state
    }: ActionContext<ITabsState, RootState>, payload: onStartStatPackageBookActionPayload) {
      const sourceTab = state.tabs.find(tab => tab.tabSessionId === payload.tabSessionId);

      if (!sourceTab) {
        console.error("sourceTab not found");
      }

      const activePath = `/package-order/${payload.orderId}`;

      const updateTabPayload: updateTabSessionsActionPayload = {
        ..._.cloneDeep(sourceTab),
        uuid: payload.uuid,
        tabSessionId: state.selectedTabId,
        activePath,
        productId: payload.productId,
        tabIndex: 0,
        // rawProduct: payload.productRaw,
        // rawProductPrice: payload.productPrice,
        orderId: payload.orderId,
        agencyId: payload.agencyId,
        agency: payload.agency
        // orderItemIds: payload.orderItemId,
        // orderItems: payload.orderItems
      };

      const createTabPayload: createTabActionPayload = {
        uuid: payload.uuid,
        rawData: {
          ..._.cloneDeep(updateTabPayload),
          tabSessionId: undefined
        }
      };

      const tabResource: TabSessionResource = (await dispatch(
        "createTab",
        createTabPayload
      ) as Awaited<createTabActionReturn>);

      const selectActiveTabPayload: selectActiveTabActionPayload = {
        tabSessionId: tabResource.entityId,
        uuid: payload.uuid
      };

      await dispatch("selectActiveTab", selectActiveTabPayload);

      try {
        const addStatPackageProductToTabSessionsPayloadArray = payload.productId.map((productId: string) => {
          const addStatPackageProductToTabSessionsPayload = {
            uuid: payload.uuid,
            tabSessionId: tabResource.entityId,
            productId
          };

          return addStatPackageProductToTabSessionsPayload;
        });

        await Promise.all(addStatPackageProductToTabSessionsPayloadArray.map((addStatPackageProductToTabSessionsPayload) => api.addStatPackageProductToTabSessions(
          tabResource.entityId,
          addStatPackageProductToTabSessionsPayload
        )));
      } catch (e) {
        console.log("error не смогли продлить жизнь продукту", e);
      }
    },

    async onStartSearch({
      dispatch,
      state,
      commit
    }: ActionContext<ITabsState, RootState>, payload: onStartSearchActionPayload) {
      const sourceTab = state.tabs.find(tab => tab.tabSessionId === payload.tabSessionId);

      console.log("sourceTab", sourceTab);

      if (!sourceTab) {
        console.error("sourceTab not found");
      }

      const activePath =
                payload.productType === "REGULAR_FLIGHT"
                  ? `/result/${payload.requestId}`
                  : payload.productType === "HOTEL"
                    ? `/hotel-result/${payload.requestId}`
                    : `/package-result/${payload.requestId}`;
      const updateTabPayload: updateTabSessionsActionPayload = {
        ..._.cloneDeep(sourceTab),
        uuid: payload.uuid,
        query: payload.query,
        orderItems: [],
        orderItemIds: [],
        customerIds: [],
        customers: [],
        searchFilter: null,
        selectedPaxComplects: [],
        rawProductData: null,
        rawProduct: null,
        productId: null,
        tabSessionId: state.selectedTabId,
        activePath,
        requestId: payload.requestId,
        productType: payload.productType
      };

      delete updateTabPayload.savedSeats;
      delete updateTabPayload.savedServices;

      const updateTabId = state.selectedTabId;

      // if (sourceTab.requestId && sourceTab.orderId) {
      //   // если уже есть поиск и заказ в нем удаляем таб и вместо него создаем новый и переходим в него и делаем поиск в нем
      //
      //   const deletePayload: deleteTabSessionsPayload = {
      //     uuid: payload.uuid,
      //     tabSessionId: payload.tabSessionId
      //   };
      //
      //   await api.deleteTabSessions(deletePayload.tabSessionId, deletePayload);
      //
      //   commit(ON_CLOSE_TAB.SUCCEEDED, payload.tabSessionId);
      //
      // const createTabPayload: createTabActionPayload = {
      //   uuid: payload.uuid,
      //   rawData: {
      //     ...updateTabPayload,
      //     tabSessionId: undefined
      //   }
      // };
      //
      //   const tabResource: TabSessionResource = (await dispatch("createTab", createTabPayload) as Awaited<createTabActionReturn>);
      //
      //   updateTabId = tabResource.entityId;
      // }
      // else {
      await dispatch("updateTab", updateTabPayload);
      // }

      const selectActiveTabPayload: selectActiveTabActionPayload = {
        tabSessionId: updateTabId,
        uuid: payload.uuid
      };

      await dispatch("selectActiveTab", selectActiveTabPayload);
    },
    async onTabExpired({
      dispatch,
      state
    }: ActionContext<ITabsState, RootState>, payload: { tabSessionId: string, uuid: string }) {
      // delete all order data from tab and go to search page
      const sourceTab = state.tabs.find(tab => tab.tabSessionId === payload.tabSessionId);

      if (!sourceTab) {
        console.error("sourceTab not found");
      }

      const updateTabPayload: updateTabSessionsActionPayload = {
        ..._.cloneDeep(sourceTab),
        uuid: payload.uuid,
        tabSessionId: state.selectedTabId,
        activePath: "/search"

      };

      await dispatch("updateTab", updateTabPayload);

      const selectActiveTabPayload: selectActiveTabActionPayload = {
        tabSessionId: state.selectedTabId,
        uuid: payload.uuid
      };

      await dispatch("selectActiveTab", selectActiveTabPayload);
    },
    async setTabExpired({
      commit
    }: ActionContext<ITabsState, RootState>, payload: setTabExpiredActionPayload) {
      commit(SET_TAB_EXPIRED.SUCCEEDED, {
        tabSessionId: payload.tabSessionId,
        expired: true
      });
    },
    async setTabUnExpired({
      commit
    }: ActionContext<ITabsState, RootState>, payload: setTabUnExpiredActionPayload) {
      commit(SET_TAB_EXPIRED.SUCCEEDED, {
        tabSessionId: payload.tabSessionId,
        expired: false
      });
    }

  },

  mutations: {
    [CREATE_TAB.SUCCEEDED](state, payload: TabSessionResource) {
      const rawData = payload.rawData ?? {};
      const newTab: ITab = {
        ...emptyTabFactory(),
        ..._.cloneDeep(rawData),
        tabSessionId: payload.entityId,
        tabSessionResource: payload
      };

      state.tabs.push(newTab);
    },
    [CREATE_TAB.FAILED](state, error) {
      console.log(error);
    },
    [UPDATE_TAB.INITIAL](state) {
      console.log("startUpdate");
    },
    [UPDATE_TAB.SUCCEEDED](state, payload: TabSessionResource) {
      const tab = state.tabs.find(tab => tab.tabSessionId === payload.entityId);
      if (tab) {
        const replaceTabContent: ITab = {
          ...emptyTabFactory(),
          ..._.cloneDeep(payload.rawData),
          tabSessionId: payload.entityId,
          tabSessionResource: payload
        };
        state.tabs.splice(state.tabs.indexOf(tab), 1, replaceTabContent);
      }
    },
    [RELOAD_TABS_ON_LOGIN.INITIAL](state) {
      state.tabs = [];
    },
    [RELOAD_TABS_ON_LOGIN.SUCCEEDED](state, payload: TabSessionResource[]) {
      console.log("RELOAD_TABS_ON_LOGIN.SUCCEEDED", payload);
      state.tabs = payload.map((tabSessionResource: TabSessionResource) => {
        let newTab: ITab = {
          ...emptyTabFactory(),
          tabSessionId: tabSessionResource.entityId,
          tabSessionResource
        };

        // берем чистый объект, чтобы взять из него все ключи и после этого проверяем есть ли такой ключ в ресурсе и если есть вносим в объект
        const emptyTabWithoutTabSession = omit(emptyTabFactory(), ["tabSessionId", "tabSessionResource"]);

        const fields = Object.keys(emptyTabWithoutTabSession) as (keyof ITab)[];

        const pickedFromTabSession: Partial<ITab> = pick((tabSessionResource.rawData as Partial<ITab>), fields);

        newTab = _.merge(newTab, pickedFromTabSession);
        console.log("newTab", newTab, newTab, pickedFromTabSession);
        return newTab;
      });
    },
    [SET_TABS_FROM_API.SUCCEEDED](state, payload: TabSessionResource[]) {
      state.rawTabs = payload;
    },
    [SELECT_ACTIVE_TAB.SUCCEEDED](state, tabSessionId: string) {
      const tabIndex = state.tabs.findIndex(tab => tab.tabSessionId === tabSessionId);
      if (tabIndex !== -1) {
        state.activeTabIndex = tabIndex;
        state.selectedTabId = tabSessionId;
      }
    },
    [ON_CLOSE_TAB.SUCCEEDED](state, tabSessionId: string) {
      const tabIndex = state.tabs.findIndex(tab => tab.tabSessionId === tabSessionId);
      if (tabIndex !== -1) {
        state.tabs.splice(tabIndex, 1);
      }
    },
    [ON_CLOSE_TAB.FAILED](_, error: any) {
      console.error(error);
    },
    [ON_CLOSE_ALL_TABS.SUCCEEDED](state) {
      state.tabs = [];
    },
    [ON_CLOSE_ALL_TABS.FAILED](_, error) {
      console.error(error);
    },
    [SET_TAB_EXPIRED.SUCCEEDED](state, {
      tabSessionId,
      expired
    }: { tabSessionId: string, expired: boolean }) {
      const tabIndex = state.tabs.findIndex(tab => tab.tabSessionId === tabSessionId);
      if (tabIndex !== -1) {
        state.tabs[tabIndex].isExpired = expired;
      }
    }
  }
});
